<template>
  <b-container>
    <h1>Videos</h1>
  </b-container>
</template>

<script>
export default {
  name: "Videos",
};
</script>

<style></style>
